import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import GridItem from '../components/slices/GridItem/GridItem';
import Form from '../components/slices/Form/Form';

const KnowledgeCenterTemplate = ({ data }) => {
  if (!data) return null;

  const knowledgeCenter = data.prismicKnowledgeCenter;
  const knowledgeCenterContent = knowledgeCenter.data || {};

  const allPosts = data.allPrismicKnowledgeCenterArticle.nodes;
  const filterTypes = knowledgeCenterContent.filter_categories;
  const filterTags = knowledgeCenterContent.filter_tags;

  const { lang, type, url } = knowledgeCenter;
  const alternateLanguages = knowledgeCenter.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const form = {
    primary: {
      title_color: 'light-blue',
      title: 'Don\'t See What You Need?',
      description_color: 'white',
      description: 'Get In Touch With A Consultant Today',
      fields: 'minimal',
      logo_above_text: 'above',
      form_id: '00739577-e25f-49d4-b1bd-6ac2fe293ffe',
      portal_id: 5625890,
      google_conversion_name: 'knowledge-center-form-success',
    },
  };
  const metaTitle = knowledgeCenterContent.meta_title || 'CloudArmy Knowledge Center';

  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={knowledgeCenterContent.body} />
      <GridItem posts={allPosts} filterTypes={filterTypes} filterTags={filterTags} />
      <Form slice={form} />
    </Layout>
  );
};

export const query = graphql`
  query KnowledgeCenterQuery($uid: String, $lang: String, $id: String) {
    prismicKnowledgeCenter(uid: {eq: $uid}, id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        display_title {
          raw
          html
          text
        }
        filter_categories {
          category
          category_display
        }
        filter_tags {
          tag
          tag_display
        }
        body {
          ... on PrismicKnowledgeCenterDataBodyTextInfo {
            id
            slice_label
            slice_type
            primary {
              description {
                html
                raw
                text
              }
              description_alignment
              description_color
              title {
                html
                raw
                text
              }
              title_alignment
              title_color
              width
              description_width
            }
          }
        }
      }
    }
    allPrismicKnowledgeCenterArticle(
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        tags
        _previewable
        url
        uid
        id
        data {
          body {
            ... on PrismicKnowledgeCenterArticleDataBodyTextInfo {
              id
              slice_label
              slice_type
              primary {
                width
                title_color
                title_alignment
                title {
                  html
                  raw
                  text
                }
                icon {
                  alt
                  copyright
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                description_color
                description_alignment
                description {
                  text
                  raw
                  html
                }
              }
            }
            ... on PrismicKnowledgeCenterArticleDataBodyEmbeddedMedia {
              id
              slice_label
              slice_type
              primary {
                embed_url
                height
                youtube_id
                width
                title_placement
                title {
                  html
                  raw
                  text
                }
                media {
                  raw
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          date
          display_title {
            html
            raw
            text
          }
          section
          image {
            copyright
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
    }
    prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(KnowledgeCenterTemplate);
